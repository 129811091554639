<template>
  <div class="container" style="background: #F3F2F7">
    <div class="title_bg">
      <div class="help_title" style="display: block; position:relative;">
<!--      <div style="display: block; position:relative;">-->
        <div class="title-top">
          <img style="vertical-align: middle;cursor: pointer; width: 110px; height: 30px;" @click="$router.push('/')" src="../../assets/img/logo.png">
          <el-divider direction="vertical"></el-divider>
          <span style="color: white;vertical-align: middle;font-size: 18px;cursor: pointer" @click="$router.push('/helpCenter')">{{ $t('help.help_center') }}</span>
        </div>
        <span v-if="showFooter" class="goto" style="cursor: pointer" @click="goBack()">{{ $t('help.go_to') }}</span>
      </div>

      <div class="help_title_sm" style="display: block; position:relative;">
        <div class="title-top">
          <img style="vertical-align: middle; width: 80px; height: 23px;" src="../../assets/img/logo.png">
        </div>
        <span v-if="showFooter == true" class="goto" @click="goBack()">{{ $t('help.go_to') }}</span>
      </div>

      <div class="help_question" style="display: block; position: relative; top:56%;left:50%;transform: translate(-50%, -50%);text-align: center;width: 40%">
        <span class="help_question_title" style="">欢迎来到帮助中心</span>
        <span class="help_question_content" style="">我能为你做点什么？</span>
      </div>
    </div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>

    <Footer_lg v-if="!this.$util._isMobile() && showFooter == true" ></Footer_lg>
    <Footer_sm v-if="this.$util._isMobile() && showFooter == true" ></Footer_sm>
<!--    <Navbar class="navbar"></Navbar>-->
  </div>
</template>

<script>
import Footer_lg from "../../components/footer/Footer_lg";
import Footer_sm from "../../components/footer/Footer_sm";
// import Navbar from "../../components/Navbar";
export default {
  name: "Home_new",
  components:{
    Footer_lg,
    Footer_sm,
    // Navbar
  },

  watch: {
    $route: {
      handler: function (val, oldVal){
        if (this.thisPage != val.path){
          this.pageNum += 1
          console.log('132123123132113123', this.pageNum)
        }
      }
    }
  },

  data(){
    return{
      showFooter: true,
      pageNum: 1,
      thisPage: ''
    }
  },

  activated() {

  },

  created() {
    if (this.$route.query.showFooter){
      this.showFooter = this.$route.query.showFooter
    }
    this.thisPage = this.$router.currentRoute.path
    console.log('aseq2esdad2', this.thisPage)
  },

  methods:{
    goBack(){
      // this.$router.push('../../'+ (localStorage.getItem('lastPath')))
      console.log('213123123123123123123', -this.pageNum)
      this.$router.go(-this.pageNum)
    }
  }

}
</script>

<style scoped>
span{
  color: #1E2329;
}

.container{
  position: relative;
}

.title-top{
  position: absolute;
  top: 18px;
  left: 120px;
}

.goto {
  color: white;
  font-size: 16px;
  padding: 4px 17px;
  border: white 1px solid;
  border-radius: 15px;
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 120px;
}

>>>.el-divider{
  position: unset !important;
  vertical-align: middle;
  margin-left: 20px;
  margin-right: 20px;
  height: 30px;
  width: 2px;
  background-color: white;
}

>>>.el-input__inner{
  height: 48px;
}

@media screen and (max-width: 1079px) {
  .lg-footer{
    display: none;
  }
  .sm-footer {
    display: block;
  }
}
@media screen and (min-width: 1079px) {
  .sm-footer {
    display: none;
  }
  .lg-footer{
    display: flex;
  }
}

@media screen and (max-width: 900px){
  .title_bg{
    height: 200px;
    background: linear-gradient(180deg, #AB54DB 0%, rgba(44, 117, 255, 0) 83%, rgba(44, 117, 255, 0) 100%);
  }

  .title-top{
    position: absolute;
    top: 18px;
    left: 20px;
  }
  .help_title{
    display: none !important;
  }
  .help_title_sm{
    display: block !important;
  }

  .goto {
    color: white;
    font-size: 16px;
    padding: 4px 17px;
    border: white 1px solid;
    border-radius: 15px;
    display: inline-block;
    position: absolute;
    top: 18px;
    right: 20px;
  }
  .help_question{
    top: 50% !important;
    width: 100% !important;
    /*margin: 0 16px !important;*/
  }
  .help_question span{
    font-size: 26px;
  }
  .help_question el-input{
    width: 80% !important;
  }
  >>>.el-input__inner{
    width: 80%;
  }
  .el-icon-search{
    right: 50px !important;
  }
  .help_question_title{
    display:block;
    color: white;
    font-size: 26px;
    margin-bottom: 0px
  }
  .help_question_content{
    display:block;
    font-size: 16px !important;
    color: #fefefe;
    margin-top: 5px;
  }
}
@media screen and (min-width: 900px){
  .navbar{
    display: none;
  }

  .help_title{
    display: block !important;
  }
  .help_title_sm{
    display: none !important;
  }

  .title_bg{
    height: 280px;
    /*background-image: url(../../assets/img/help/help_title.svg);*/
    background-image: url(../../assets/img/help/help_title.png);
    background-size: cover;
    /*background-repeat: round;*/
  }
  .help_question_title{
    display:block;
    color: white;
    font-size: 36px;
    margin-bottom: 0px
  }

  .help_question_content{
    display:block;
    font-size: 20px !important;
    color: #fefefe;
    margin-top: 5px;
    opacity: 0.8
  }

}

</style>
